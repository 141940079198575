// 我的应用——详情
// mxy
<template>
  <a-layout class="box flex-row justify-center">
    <!-- <a-layout class="level2Main"> -->
    <div class="main flex-row justify-center">
      <!-- 左侧菜单栏 -->
      <div class="leftMenu justify-center">
        <img class="img" :src="data.applicationIcon" />
        <!-- <HomeOutlined class="back" @click="back" /> -->
        <span class="back_title">{{ appName }} </span>
        <a-spin :spinning="spinning">
          <a-layout-sider>
            <a-menu mode="inline" v-model:selectedKeys="selectedKeys">
              <a-empty description="暂无数据" v-if="list.length == 0" />
              <a-menu-item
                v-for="item in list"
                :key="item.id"
                @click="add(item)"
                :title="item.name"
              >
                <div class="flex-row list_item">
                  <div
                    v-if="item.definedIcon === null"
                    :style="
                      'font-size:14px; background-color:' +
                      bgColor(item.iconBgColor) +
                      ';'
                    "
                    :class="[
                      'list-item-img',
                      'ibps-icon',
                      'ibps-icon-' + item.icon,
                    ]"
                    alt=""
                  ></div>
                  <img
                    v-else
                    class="list-item-definedImg"
                    :src="item.definedIcon"
                  />
                  <span>{{
                    item.definedName === null ? item.name : item.definedName
                  }}</span>
                </div>
              </a-menu-item>
            </a-menu>
          </a-layout-sider>
        </a-spin>
      </div>

      <!-- 中部 -->
      <a-layout class="right">
        <!-- 页面 -->
        <a-empty description="暂无数据" v-if="selectList.length == 0" />
        <a-spin :spinning="spinning">
          <!-- <a-popover class="popover">
            <template #content>
              <p>全部关闭</p>
            </template>
            <div>
              <DownOutlined />
            </div>
          </a-popover> -->
          <a-tabs
            v-if="selectList.length !== 0"
            v-model:activeKey="activeKey"
            hide-add
            type="editable-card"
            @edit="onEdit"
            @change="change"
          >
            <a-tab-pane
              v-for="pane in selectList"
              :key="pane.id"
              :tab="pane.name"
              :closable="selectList.length == 1 ? false : true"
            >
              <div class="iframe-layout">
                <keep-alive>
                  <router-view :updateProjectURL="updateProjectURL">
                  </router-view>
                </keep-alive>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-spin>

        <!-- <router-view /> -->
      </a-layout>
    </div>
    <!-- </a-layout> -->
  </a-layout>
</template>

<script>
// import { defineComponent } from 'vue'
import api from '@/api/API'
import { getCodePlatformUrl } from '@/api/config'
import IframePage from './IframePage'
// import { minheight } from '../components/sameVariable'
// import { minheight } from '../components/sameVariable'
// import { DownOutlined } from '@ant-design/icons-vue'

// import PageHead from '@/components/PageHead'
export default ({
  components: {
    // eslint-disable-next-line vue/no-unused-components
    IframePage,
    // DownOutlined,
    // HomeOutlined,
    // 'page-head': PageHead,
    // FullscreenOutlined,
    // 'usage-record': UsageRecord,
  },
  data () {
    return {
      codePlatformUrl: '',
      collapsed: false,
      activeKey: '',
      newTabIndex: 0,
      selectedKeys: [],
      list: [],
      spinning: true,
      popover: false,
      tenantId: '',
      listONe: {},
      selectList: [],
      removeList: [],
      appName: '',
      url: '',
      token: '',
      data: {},
      applicationCode: '',
      updateProjectURL: '',

      // closable: true,
    }
  },
  // 页面加载事件
  created () {
    const tenantList = JSON.parse(localStorage.getItem('tenantList'))
    this.tenantId = tenantList.tenantId
    this.token = JSON.parse(localStorage.getItem('token'))
  },
  mounted () {
    this.getApplicationDetails()
  },
  // 方法
  methods: {
    // 返回对应的背景颜色
    bgColor (color) {
      if (color !== undefined) {
        switch (color) {
          case 'blue-madison':
            return 'mediumblue'
          case 'green-meadow':
            return 'mediumseagreen'
          case 'yellow-crusta':
            return 'yellowgreen'
          case 'red-pink':
            return 'hotpink'
          default:
            return color
        }
      }
    },
    // 获取数据
    getApplicationDetails () {
      this.spinning = true
      const params = this.$route.params
      api
        .get('/api/app/sys-application-on/menu', {
          params: params,
        })
        .then(({ data }) => {
          this.applicationCode = data.applicationCode
          this.data = data
          this.appName = data.applicationName
          if (this.appName !== undefined) {
            document.title = this.appName
          }
          if (data.authResList.length === 0) {
            this.$message.warn('暂无数据')
          } else {
            this.list = data.authResList
          }
          this.removeList = data.authResList
          this.spinning = false
          if (this.appName === '威申请') {
            this.codePlatformUrl = 'https://wsmart.yaweicloud.com/oldpcui'
          } else {
            this.codePlatformUrl = getCodePlatformUrl()
          }
          this.selectKey()
        })
        .catch(err => {
          this.spinning = false
          console.log(err)
          // this.$message.error('获取失败' + err)
        })
    },
    selectKey () {
      var dataName = [this.list[0].id]
      this.selectedKeys = dataName
      this.activeKey = this.removeList[0].id
      this.selectList = [this.removeList[0]]

      this.url = this.codePlatformUrl + '/#' + this.list[0].defaultUrl + '?layout=none&access_token=' + this.token.access_token + '&tenant_id=' + this.tenantId +
      '&appCode=' + this.applicationCode
      // this.url = codePlatformUrl + '/#' + this.list[0].defaultUrl + '?layout=none&access_token=' + this.token.access_token + '&tenant_id=' + this.tenantId

      // this.url = this.codePlatformUrl + '/#' + this.list[0].defaultUrl + '?layout=none&access_token=' + this.token.access_token + '&tenant_id=' + this.tenantId
      // console.log('跳转前的传参defaultUrl=', this.list[0].defaultUrl)
      this.$router.push({ name: 'IframePage', params: { url: this.list[0].defaultUrl, appCode: this.applicationCode } })
    },
    // 返回
    back () {
      this.$router.push({ path: '/FormManagement/FromDeploy' })
    },
    // 新增和删除页签的回调
    onEdit (targetKey, action) {
      this[action](targetKey)
    },
    // 点击标签
    change (activeKey) {
      if (this.applicationCode.length === 0) {
        this.$message.error('请等待应用菜单加载完成')
      }
      this.activeKey = activeKey
      this.selectedKeys[0] = this.activeKey
      this.add()
    },
    // 点击增加标签
    add (item) {
      if (item) {
        this.selectList.push(item)
        this.activeKey = item.id
        this.selectList = [...new Set(this.selectList)]
        // this.url = this.codePlatformUrl + '/#' + item.defaultUrl + '?layout=none&access_token=' + this.token.access_token + '&tenant_id=' + this.tenantId
        // console.log('跳转前的传参=', item.defaultUrl)
        // console.log('add的item', item)
        // console.log('url', this.url)
        this.updateProjectURL = item.defaultUrl
        this.$router.push({ name: 'IframePage', params: { url: item.defaultUrl, appCode: this.applicationCode } })
      } else {
        const obj = this.selectList.find(item => item.id === this.activeKey)
        this.updateProjectURL = obj.defaultUrl
        this.$router.push({ name: 'IframePage', params: { url: obj.defaultUrl, appCode: this.applicationCode } })
        // console.log(obj.defaultUrl, 'obj.defaultUrl')
        // this.url = this.codePlatformUrl + '/#' + obj.defaultUrl + '?layout=none&access_token=' + this.token.access_token + '&tenant_id=' + this.tenantId
        // this.$router.push({ name: 'IframePage', query: { url: obj.defaultUrl } })
      }
    },
    // 删除标签
    remove (targetKey) {
      let activeKey = this.activeKey
      let lastIndex
      this.selectList.forEach((pane, i) => {
        if (pane.id === targetKey) {
          lastIndex = i - 1
        }
      })
      const panes = this.selectList.filter(pane => pane.id !== targetKey)
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].id
        } else {
          activeKey = panes[0].id
        }
      }
      this.selectList = panes
      this.activeKey = activeKey
      this.selectedKeys = [this.activeKey]
      this.change(activeKey)
    },
  },
})
</script>
<style  lang="scss" scoped>
@import "@/assets/common.scss";

.box {
  width: 100%;
  height: 100vh;
  .main {
    width: 98%;
    height: 100vh;
    position: relative;
    padding-top: 24px;
    background-color: #eff2f5;

    .iframe-layout {
      text-align: center;
      width: 100%;
      // height: 100vh;
      height: 90vh;
      .iframe {
        border: 0px none;
        height: 90vh;
        width: 99%;
        // margin: 5px;
        // margin: 10px;
      }
    }

    .leftMenu {
      // position: fixed;
      max-width: 200px;
      min-height: 60vh;
      width: 200px;
      height: 92vh;
      .img {
        width: 30px;
        height: 30px;
      }
      .back {
        font-size: 20px;
        margin-bottom: 10px;
      }
      .back_title {
        font-size: 18px;
        margin: 0 0 20px 16px;
      }
      .ant-empty {
        margin-top: 20vh;
      }
    }
    .right {
      position: relative;
      margin-left: 20px;
      width: 83%;
      height: 94.5vh;
      background-color: #fff;
      .ant-empty {
        margin-top: 40vh;
      }
    }
  }
}
.list_item {
  height: 40px;
  line-height: 40px;
  position: relative;
}
.list-item-img {
  position: relative;
  top: 14px;
  //left: -10px;
  margin-right:10px;
  display: inline-block;
  height: 20px;
  width: 20px;
  color: #36383c;
  // color: greenyellow;
  /* margin: 20px 22px 20px 30px; */
  text-align: center;
  font-weight: bold;
  &:hover {
  color: #333333;
  }
}
.list-item-definedImg {
  position: relative;
  top: 10px;
  margin-right: 10px;
  display: inline-block;
  height: 20px;
  width: 20px;
  color: #36383c;
  // color: greenyellow;
  /* margin: 20px 22px 20px 30px; */
  text-align: center;
  font-weight: bold;
  &:hover {
  color: #333333;
  }
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  border-left: 1px solid #fff;
}
// .ant-menu-item{
//   margin-top: 0px;
// }
.ant-layout-sider {
  background-color: #fff;
}
::v-deep(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected){
  background-color: #eeeeee;
  color: #333333;
}
::v-deep(.ant-menu-item:hover){
  color: #333333;
}
::v-deep(.ant-menu-item::after){
  border-right: 3px solid #728094;
}
::v-deep(.ant-layout-sider-children::-webkit-scrollbar) {
  width: 10px;
  /**/
}
::v-deep(.ant-layout-sider-children::-webkit-scrollbar-track) {
  background: #eee;
  border-radius: 3px;
}
::v-deep(.ant-layout-sider-children::-webkit-scrollbar-thumb) {
  background: #d1d1d1;
  border-radius: 10px;
}
::v-deep(.ant-layout-sider-children::-webkit-scrollbar-thumb:hover) {
  background: #d1d1d1;
}
::v-deep(.ant-menu-item) {
  margin-top: 0;
  color: #999;
}
::v-deep(.ant-spin-container .ant-layout-sider-dark) {
  width: 100%;
  margin-top: 10px;
}
::v-deep(.ant-layout-sider-children) {
  position: relative;
  left: 0px;
  width: 100%;
  max-height: 87vh;
  min-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 0;
}
::v-deep(.ant-menu-item-selected .list-item-img) {
  color: #333333;
  // color: #1890ff;
}
::v-deep(.ant-menu-item-active .list-item-img) {
  color: #333333;
  // color: #1890ff;
}
::v-deep(.ant-tabs-bar) {
  width: 100%;
  margin: 0px;
}

// .popover {
//   position: absolute;
//   left: 94%;
//   width: 38px;
//   height: 38px;
//   line-height: 38px;
//   text-align: center;
//   border: 1px solid #ccc;
//   z-index: 999;
// }
</style>
