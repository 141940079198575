<template>
    <div>
        <iframe class="iframe" :src="url"></iframe>
    </div>
</template>
<script>
import { getCodePlatformUrl } from '@/api/config'
import api from '@/api/API'
export default ({
  name: 'IframePage',
  props: {
    updateProjectURL: {
      type: String,
    },
  },
  data () {
    return {
      url: '',
      targetUrl: '',
      appCode: '',
    }
  },
  created () {
    this.initDate()
  },

  methods: {
    initDate () {
      let codePlatformUrl = getCodePlatformUrl()

      const tenantId = JSON.parse(localStorage.getItem('tenantList')).tenantId
      const accessToken = JSON.parse(localStorage.getItem('token')).access_token
      if (this.updateProjectURL !== '') {
        this.targetUrl = this.updateProjectURL
      } else {
        this.targetUrl = this.$route.params.url
      }
      this.appCode = this.$route.params.appCode
      if (this.appCode === 'wsq_20220629') {
        codePlatformUrl = 'https://wsmart.yaweicloud.com/oldpcui'
      }
      // console.log('iframe的targetUrl', this.targetUrl)
      this.url = codePlatformUrl + '/#' + this.targetUrl + '?layout=none&access_token=' + accessToken + '&tenant_id=' + tenantId + '&appcode=' + this.appCode
      console.log(this.url)
    },
  },
})
</script>
<style lang="scss" scoped>
    .iframe {
        border: 0px none;
        height: 90vh;
        width: 99%;
        // margin: 5px;
        // margin: 10px;
      }
</style>
